import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
import config from './config';
import App from './App';

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
        domain: 'auth.icitizen.com',
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: config.cognito.OAUTH.REDIRECT_SIGN_IN,
        redirectSignOut: config.cognito.OAUTH.REDIRECT_SIGN_OUT,
        responseType: 'code'
    }
  },
  API: {
    endpoints: [
      {
        name: 'users',
        endpoint: config.apiGateway.URL_USERS,
        region: config.apiGateway.REGION
      },
      {
        name: 'articles',
        endpoint: config.apiGateway.URL_ARTICLES,
        region: config.apiGateway.REGION
      },
      {
        name: 'donations',
        endpoint: config.apiGateway.URL_DONATIONS,
        region: config.apiGateway.REGION
      },
      {
        name: 'comms',
        endpoint: config.apiGateway.URL_COMMS,
        region: config.apiGateway.REGION
      },
      {
        name: 'questions',
        endpoint: config.apiGateway.URL_QUESTIONS,
        region: config.apiGateway.REGION
      },
      {
        name: 'sweeps',
        endpoint: config.apiGateway.URL_SWEEPS,
        region: config.apiGateway.REGION
      },
      {
        name: 'comments',
        endpoint: config.apiGateway.URL_COMMENTS,
        region: config.apiGateway.REGION
      },
      {
        name: 'polls',
        endpoint: config.apiGateway.URL_POLLS,
        region: config.apiGateway.REGION
      },
      {
        name: 'email',
        endpoint: config.apiGateway.URL_EMAIL,
        region: config.apiGateway.REGION
      },
      {
        name: "aac",
        endpoint: config.apiGateway.URL_AAC,
        region: config.apiGateway.REGION
      },
      {
        name: "getChatResponse",
        endpoint: config.apiGateway.URL_GETCHATRESPONSE,
        region: config.apiGateway.REGION,
        service: "lambda"
      },
      {
        name: "getChatsInLastDay",
        endpoint: config.apiGateway.URL_GETCHATSINLASTDAY,
        region: config.apiGateway.REGION,
        service: "lambda"
      },
      {
        name: "getGreeting",
        endpoint: config.apiGateway.URL_GENERATEGREETING,
        region: config.apiGateway.REGION,
        service: "lambda"
      }
    ]
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  }
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
