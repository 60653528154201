import { Storage } from "aws-amplify";

export function getS3Image(key) {
  return Storage.get(key, {level: "public", expires:60*60*24});
}

export async function uploadS3Image(uid, file) {
  const settings = {
    contentType: file.type,
    level: "public"
  };
  // file name is user guid
  const filename = uid;

  // delete current avatar (if existing)
  await Storage.vault.remove(filename, file, settings);

  // upload new avatar from file
  return Storage.vault.put(filename, file, settings);
}
